#front-testimony-1 {
	// https://stackoverflow.com/questions/23208200/how-to-darken-a-background-using-css
	background:
	  /* top, transparent black, faked with gradient */
	  linear-gradient(
	    rgba(0, 0, 0, 0.5),
	    rgba(0, 0, 0, 0.5)
	  ),
	  /* bottom, image */
	  url("images/1.png");

  	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

#front-testimony-2 {
	// https://stackoverflow.com/questions/23208200/how-to-darken-a-background-using-css
	background:
	  /* top, transparent black, faked with gradient */
	  linear-gradient(
	    rgba(0, 0, 0, 0.5),
	    rgba(0, 0, 0, 0.5)
	  ),
	  /* bottom, image */
	  url("images/10.png");

  	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

#front-testimony-3 {
	// https://stackoverflow.com/questions/23208200/how-to-darken-a-background-using-css
	background:
	  /* top, transparent black, faked with gradient */
	  linear-gradient(
	    rgba(0, 0, 0, 0.5),
	    rgba(0, 0, 0, 0.5)
	  ),
	  /* bottom, image */
	  url("images/7.png");

  	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

#front-testimony-4 {
	// https://stackoverflow.com/questions/23208200/how-to-darken-a-background-using-css
	background:
	  /* top, transparent black, faked with gradient */
	  linear-gradient(
	    rgba(0, 0, 0, 0.5),
	    rgba(0, 0, 0, 0.5)
	  ),
	  /* bottom, image */
	  url("images/4.png");

  	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

#front-header-1 {
	// https://stackoverflow.com/questions/23208200/how-to-darken-a-background-using-css
	background:
	  /* top, transparent black, faked with gradient */
	  linear-gradient(
	    rgba(0, 0, 0, 0.5),
	    rgba(0, 0, 0, 0.5)
	  ),
	  /* bottom, image */
	  url("images/stock-students-field.png");

    background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

#front-header-2 {
	// https://stackoverflow.com/questions/23208200/how-to-darken-a-background-using-css
	background:
	  /* top, transparent black, faked with gradient */
	  linear-gradient(
	    rgba(0, 0, 0, 0.3),
	    rgba(0, 0, 0, 0.3)
	  ),
	  /* bottom, image */
	  url("images/front-header-2.png");

    background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

#front-header-3 {
	// https://stackoverflow.com/questions/23208200/how-to-darken-a-background-using-css
	background:
	  /* top, transparent black, faked with gradient */
	  linear-gradient(
	    rgba(0, 0, 0, 0.3),
	    rgba(0, 0, 0, 0.3)
	  ),
	  /* bottom, image */
	  url("images/front-header-3.png");

    background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

#front-header-4 {
	// https://stackoverflow.com/questions/23208200/how-to-darken-a-background-using-css
	background:
	  /* top, transparent black, faked with gradient */
	  linear-gradient(
	    rgba(0, 0, 0, 0.3),
	    rgba(0, 0, 0, 0.3)
	  ),
	  /* bottom, image */
	  url("images/front-header-4.png");

    background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

#front-header-img.GN-slanted-image {
	// https://stackoverflow.com/questions/23208200/how-to-darken-a-background-using-css
	background:
	  /* top, transparent black, faked with gradient */
	  linear-gradient(
	    rgba(0, 0, 0, 0.5),
	    rgba(0, 0, 0, 0.5)
	  ),
	  /* bottom, image */
	  url("images/stock-students-field.png");

    background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

#about-us-header-img.GN-slanted-image {
	// https://stackoverflow.com/questions/23208200/how-to-darken-a-background-using-css
	background:
	  /* top, transparent black, faked with gradient */
	  linear-gradient(
	    rgba(0, 0, 0, 0.5),
	    rgba(0, 0, 0, 0.5)
	  ),
	  /* bottom, image */
	  url("images/6.png");

  background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

#services-header-img.GN-slanted-image {
	// https://stackoverflow.com/questions/23208200/how-to-darken-a-background-using-css
	background:
	  /* top, transparent black, faked with gradient */
	  linear-gradient(
	    rgba(0, 0, 0, 0.5),
	    rgba(0, 0, 0, 0.5)
	  ),
	  /* bottom, image */
	  url("images/6.png");

  background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}