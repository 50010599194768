// Override bootstrap/_pagination.scss:35
.page-link {
	position: relative;
	display: inline-block;
	line-height: inherit;
	border: transparent;
	margin-left: inherit;
	padding: 0.5rem 0.5rem;
	background-color: inherit;
}

// Override 
:root {
	// --cc-bg: #fff;
	--cc-text: #444444;
	--cc-btn-primary-bg: #0077b6;
	// --cc-btn-primary-text: var(--cc-bg);
	// --cc-btn-primary-hover-bg: #1d2e38;
	// --cc-btn-secondary-bg: #eaeff2;
	// --cc-btn-secondary-text: var(--cc-text);
	// --cc-btn-secondary-hover-bg: #d8e0e6;
	// --cc-toggle-bg-off: #919ea6;
	// --cc-toggle-bg-on: var(--cc-btn-primary-bg);
	// --cc-toggle-bg-readonly: #d5dee2;
	// --cc-toggle-knob-bg: #fff;
	// --cc-toggle-knob-icon-color: #ecf2fa;
	// --cc-block-text: var(--cc-text);
	// --cc-cookie-category-block-bg: #f0f4f7;
	// --cc-cookie-category-block-bg-hover: #e9eff4;
	// --cc-section-border: #f1f3f5;
	// --cc-cookie-table-border: #e9edf2;
	// --cc-overlay-bg: rgba(4, 6, 8, 0.85);
	// --cc-webkit-scrollbar-bg: #cfd5db;
	// --cc-webkit-scrollbar-bg-hover: #9199a0;
}