// Custom CSS for the GN project

.doublespace {
	line-height: 1.69;
}

.page-link {
	color: $text-color;
}

.footer-heading {
	text-align: center;
}

.services-title-dark {
	color: white;
	margin: auto;
}

.carousel-control-prev-icon {
	position: absolute;
	bottom: 1em;
}

.carousel-control-next-icon {
	position: absolute;
	bottom: 1em;
}

.carousel-indicators {
	bottom: 1em;	
}

.GN-top-carousel {

}


$header-jumbotron-height-desktop: 600px;
$header-jumbotron-height-mobile: 1000px;

.front-page-header-carousel-jumbotron {
	height: $header-jumbotron-height-desktop;
	overflow: visible;
	margin-bottom: 0;
}

@include media-query($on-palm) {
	.front-page-header-carousel-jumbotron {
	  height: $header-jumbotron-height-mobile;
	}
}

$testimony-jumbotron-height-desktop: 300px;
$testimony-jumbotron-height-mobile: 550px;

.front-page-testimony-carousel-jumbotron {
	height: $testimony-jumbotron-height-desktop;
	overflow: auto;
	margin-bottom: 0;
}

@include media-query($on-palm) {
	.front-page-testimony-carousel-jumbotron {
	  height: $testimony-jumbotron-height-mobile;
	}
}

// Unstyle buttons
// https://stackoverflow.com/questions/2460100/remove-the-complete-styling-of-an-html-button-submit
button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

#submit-button {
	font-weight: light !important;
	font-size: 25px;
}

.GN-button {
	background-color: $brand-color;
	padding: 0.5rem;
	padding-left: 0.6rem;
	padding-right: 0.6rem;
	color: white !important;
	border-radius: 0.15em;
}

.GN-button-alt {
	z-index: 1;
	background-color: white !important;
	padding: 0.5rem;
	padding-left: 0.6rem;
	padding-right: 0.6rem;
	color: $brand-color;
	border-radius: 0.15em;
}

.GN-button-footer {
	z-index: 1;
	display:block;
	margin: auto;
	width: 120px;
	text-align: center;
	margin-bottom: 50px;
	margin-top: 1.5em;
}


.testimonial-name {
	font-weight: bolder;
}

.title-center {
	margin-top: 24px;
	font-weight: bolder;
	text-align: center;
}

.GN-body {
	text-align: center;
	margin-top: 24px;
}

.GN-about-desc {
	text-align: center;
	margin-bottom: 75px;
}

.GN-title {
	margin-top: 1em;
	margin-bottom: 1em;
	text-align: center;
	font-weight: 800;
	font-size: 2em;
	overflow: hidden;
}

.GN-sub-title {
	text-align: center;
	font-weight: 600;
	font-size: 40px;
}

.GN-sub-title-override {
	overflow: auto;
	text-align: center;
	font-weight: 600;
	font-size: 40px;


	margin-bottom: 80px;
}

.GN-form {
	font-weight: bold;
	font-size: 40px;
}

.GN-form-hidden {
	display: none;
}

.GN-form-section {
	margin-bottom: 2em;
}

// https://codepen.io/mblode/pen/VwGxaO?editors=1100
.GN-form-button-label {
	display: inline-block;
	padding: 0.5em 0.8em;
	margin: 0.2em;
	cursor: pointer;
	color: black;
	border-radius: 0.15em;
	background: lighten($background-color, 8%);
	// box-shadow: 0 3px 10px rgba(0,0,0,0.2), inset 0 -3px 0 rgba(0,0,0,0.22);
	transition: 0.3s;
	user-select: none;

	&:hover {
	background: darken(white, 10%);
	color: darken(black, 10%);
	// box-shadow: 0 3px 10px rgba(0,0,0,0.2), inset 0 -3px 0 rgba(0,0,0,0.32);
	}
	&:active {
	transform: translateY(2px);
	// box-shadow: 0 3px 10px rgba(0,0,0,0.2), inset 0px -1px 0 rgba(0,0,0,0.22);
	}
	@media (max-width: 40em) {
	padding: 0em 1em 3px;
	margin: 0.25em;
	}

	font-weight: lighter;
}

.GN-form-button:checked + .GN-form-button-label{
	background: $brand-color;
	color: white;
}

.GN-form-button:not(:checked) + .GN-form-button-label{
	background-color: lighten($text-color, 40%);
	color: white;

}

.GN-form-text-input {
	border: 1px solid;
	border-radius: 0.15em;

	font-weight: light;
	font-size: 20px;
	color: black;

	padding: 0.5em 0.75em;
	margin: 0.2em;
	width: 75%;

	background: transparent;
}

// https://dev.to/alvaromontoro/create-a-tag-cloud-with-html-and-css-1e90
.GN-tag-cloud {
	list-style: none;
	padding-left: 0;

	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.GN-tag-cloud a {
	color: black;
	display: block;
	font-size: 1.5rem;
	padding: 7px 12px;
	text-decoration: none;
	position: relative;
	z-index: 3;

	//Pill shapes
	background-color: lighten($background-color, 8%);
	border-radius: 0.15em;
	margin-left: 7px;
	margin-top: 5px;

	&:hover{
		color: white;
		background-color: $brand-color;
	}
}

// Fake random color
// ul.GN-tag-cloud li:nth-child(2n+1) a { color: white !important; }
// ul.GN-tag-cloud li:nth-child(3n+1) a { color: white !important; }
// ul.GN-tag-cloud li:nth-child(4n+1) a { color: white !important; }

.GN-focus{
	overflow: auto;
	text-align: center;
	font-size: 150px;
	font-weight: bold;
	color: #023E8A;
	position: absolute;
	left: 0;
	right: 0;
	top: 20%;
	margin-left: auto;
	margin-right: auto;
	z-index: -1;
}

.large-text-padding{
	padding-left: 140px;
	padding-right: 140px;
}

.GN-list {
	margin-top: 50px;
	text-align: center;
	list-style-type: none;
}

.GN-list li {
	margin-top: 15px;
}

.GN-list a {
	color: white;
	font-size: 1.5em;
	margin-top: 0.4em;
}

.GN-testimonial-body {
	margin-bottom: 30px;
}

// http://www.coding-dude.com/wp/css/create-a-triangle/
.GN-slanted::before {
	content: "";
	display: block;
	margin-bottom: -100px;

	position: relative;
	bottom: 200px;

	width: 100vw;
  height: 101px;
  background: #0077B6;
  clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
}

// https://coderwall.com/p/hkgamw/creating-full-width-100-container-inside-fixed-width-container
.GN-slanted {
	z-index: 0;
	position: relative;
	padding-top: 100px;
	padding-bottom: 100px;
	margin-left: -50vw;
	margin-top: 150px;
	left: 50%;
	top: 50%;
	width: 100vw;
	background: #0077B6;
}

.GN-slanted-body {
	text-align: center;
	color: white;
	font-weight: bold;
	margin-bottom: 100px;
}

.GN-slanted::after {
	content: "";
	display: block;
	margin-bottom: -100px;

	position: relative;
	bottom: -100px;

	width: 100vw;
  height: 100px;
  background: #0077B6;
  clip-path: polygon(0% 0%, 0% 100%, 100% 0%);
}

.GN-slanted-image {
	position: relative;
	text-align: center;

	margin-left: -50vw;
	padding-top: 24px;
	padding-bottom: 24px;

	left: 50%;
	width: 100vw;


	// z-index: -10;
}

.GN-fullwidth-object {
	position: relative;

	margin-left: -50vw;
	left: 50%;
	width: 100vw;
}

.GN-slanted-image h1,
.GN-slanted-image h2,
.GN-slanted-image h4{
	margin-bottom: 0.5em;
	z-index: 1;
	color: white;
	overflow: visible;
	text-shadow: 0px 2px 10px black;
}

.GN-slanted-image h2{
	margin-top: 1em;
}

.front-testimony-text {
	text-align: left;
	z-index: 1;
	color: white;
	text-shadow: 0px 2px 10px black;
	overflow: visible;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.imgbox {
		display: grid;
		height: 100%;
}
.center-fit {
		z-index: 0;
		position: relative;
		margin-left: -50vw;
		margin-bottom: 20vw;
		margin-top:-33vw;
		left: 50%;
		top: 50%;
		max-width: 500%;
		width: 100vw;
}

body {
  background: #fcfcfc;
  margin: 0;
}

.container {
  width: 900px;
  min-width: 900px;
  margin: 50px auto;
}

.GN-footer-icons {
	font-size: 1.5em;
	margin-left: 0.25em;
	margin-right: 0.25em;
}